import React from 'react';
import PropTypes from 'prop-types';

import { AccountBox, BarChart, Business, FolderOpen } from '@material-ui/icons';

const pagesAuthenticated = (t, permissions, unit) => {
    let protectedRoutes = {};

    const hasUnitPermission = permissions.find((perm) => perm.name === 'UNIT');

    if (hasUnitPermission) {
        protectedRoutes = { ...protectedRoutes, ...protectedEntries(t) };
    }

    const hasAdminPermission = permissions.find((perm) => perm.name === 'ADMIN');

    if (hasAdminPermission) {
        protectedRoutes = { ...protectedRoutes, ...adminEntries(t) };
    }

    const perms = [];

    if (unit && unit.protectedRoutes) {
        const unitDirectories = [...(unit.protectedRoutes || [])];

        if (unit?.externalReports?.length) {
            unitDirectories.push({
                title: t('Other reports'),
                href: false,
                subDirectories: unit.externalReports.map((report, i) => ({
                    title: report.name,
                    href: `/report/${i}`
                }))
            });
        }

        protectedRoutes = {
            DISCOVERY: {
                title: t('Analytics'),
                icon: <BarChart />,
                permission: ['DASHBOARD'],
                directories: unitDirectories
            },
            ...protectedRoutes
        };
    }

    Object.values(protectedRoutes).forEach((value) => {
        perms.push(value);
    });

    return perms;
};

const SubMenuItem = ({ title }) => (
    <span
        style={{
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500
        }}
    >
        {title}
    </span>
);

SubMenuItem.propTypes = {
    title: PropTypes.string
};

const protectedEntries = (t) => ({
    CAMPAIGNMAPPER: {
        title: t('Campaigns'),
        icon: <FolderOpen />,
        permission: ['UNIT'],
        directories: [
            {
                title: 'Campaign mapper',
                href: '/mapper'
            }
        ]
    },
    ACCOUNT: {
        title: t('Account'),
        icon: <Business />,
        permission: ['UNIT'],
        directories: [
            {
                title: t('Settings'),
                href: '/unit/profile'
            },
            {
                title: t('Users'),
                href: '/unit/users'
            }
        ]
    }
});

const adminEntries = (t) => ({
    ADMIN: {
        title: 'Admin',
        icon: <AccountBox />,
        permission: ['ADMIN'],
        directories: [
            {
                title: t('Report administration'),
                href: '/admin/report'
            },
            {
                title: t('Unit administration'),
                href: '/admin/unit'
            }
        ]
    }
});

export default pagesAuthenticated;
