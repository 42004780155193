import React, { useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { RouteWithLayout } from 'common/components';
import { AuthContext } from 'context/AuthContext';

import { Context as ActiveUnitContext } from '../context/units/activeUnitContext';
import {
    EmptyWithActiveUnit as EmptyWithActiveUnitLayout,
    Main as MainLayout,
    Minimal as MinimalLayout
} from '../layouts';
import {
    AdvertiseProducts,
    Advertiser,
    CampaignList,
    Cookie as CookiePolicyView,
    NotFound as NotFoundView,
    PowerBiReport,
    PowerBiSharedReport,
    ResetPassword as ResetPasswordView,
    Settings as SettingsView,
    SignIn as SignInView,
    UnitBudget,
    UnitSettings,
    Users
} from '.';
import AccountNewConnection from './Account/components/AccountNewConnection/AccountNewConnection';
import AccountConnectionsBrowsePage from './Account/components/AccountNewConnection/components/AccountConnectionsBrowsePage';
import AccountConnectPage from './Account/components/AccountNewConnection/components/AccountConnectPage';
import AccountRecovery from './AccountRecovery/AccountRecovery';
import AccountRecoveryConfirmation from './AccountRecoveryConfirmation/AccountRecoveryConfirmation';
import AdminReport from './AdminReport';
import AdminUnit from './AdminUnit';
import { CampaignView } from './Campaign/CampaignView';
import { CampaignMapper } from './CampaignMapper';
import { ExternalReport } from './ExternalReport';
import InvalidInviteToken from './NewUserPassword/InvalidInviteToken';
import InviteUserAccount from './NewUserPassword/InviteUserAccount';
import ProductTableView from './Products/ProductTable/ProductTableView';
import InvalidRecoveryToken from './ResetPassword/InvalidRecoveryToken';
import TargetingTemplates from './Targetings/TargetingTemplates';
import TotalUsers from './TotalUsersOverview/TotalUsers';

const onResetPassword = (data) => {
    alert(`reset ${JSON.stringify(data)}`);
};

export const Routes = () => {
    const { authenticated } = useContext(AuthContext);
    const {
        state: { activeUnit, activePermissions, mePermissions }
    } = useContext(ActiveUnitContext);
    const currentPermissions = mePermissions.length > 0 ? activePermissions : null;

    return (
        <Switch>
            <RouteWithLayout
                exact
                guarded
                authenticated={authenticated}
                layout={MainLayout}
                from='/'
                to={activeUnit?.landingPage}
            />

            {/* Advertise */}
            <RouteWithLayout
                component={Advertiser}
                exact
                authenticated={authenticated}
                layout={MainLayout}
                path='/advertiser'
            />
            <RouteWithLayout
                component={CampaignMapper}
                currentPermissions={currentPermissions}
                permitted={['ADMIN', 'UNIT']}
                exact
                authenticated={authenticated}
                layout={MainLayout}
                path='/mapper'
            />
            <RouteWithLayout
                component={AdvertiseProducts}
                exact
                authenticated={authenticated}
                layout={MainLayout}
                path='/advertise'
            />
            <RouteWithLayout
                component={AdvertiseProducts}
                exact
                authenticated={authenticated}
                layout={MainLayout}
                path='/advertise/:productId'
            />
            <RouteWithLayout
                component={AdvertiseProducts}
                exact
                guarded
                authenticated={authenticated}
                layout={MinimalLayout}
                path='/advertiseproduct'
            />

            <RouteWithLayout
                component={PowerBiReport}
                exact
                authenticated={authenticated}
                layout={MainLayout}
                path='/powerbi/report/:reportId/:groupId'
            />

            <RouteWithLayout
                component={ExternalReport}
                exact
                authenticated={authenticated}
                layout={MainLayout}
                path='/report/:reportIndex'
            />

            {/* Products and campaigns */}
            <RouteWithLayout
                component={CampaignList}
                guarded
                exact
                currentPermissions={currentPermissions}
                permitted={['CAMPAIGNS', 'ADMIN']}
                authenticated={authenticated}
                layout={MainLayout}
                path='/campaigns'
            />
            <RouteWithLayout
                component={CampaignView}
                guarded
                exact
                currentPermissions={currentPermissions}
                permitted={['CAMPAIGNS', 'ADMIN']}
                authenticated={authenticated}
                layout={EmptyWithActiveUnitLayout}
                path='/campaigns/:id'
            />
            <RouteWithLayout
                component={ProductTableView}
                exact
                guarded
                currentPermissions={currentPermissions}
                permitted={['ADMIN', 'PRODUCT']}
                authenticated={authenticated}
                layout={MainLayout}
                path='/products'
            />

            {/* Admin paths */}

            <RouteWithLayout
                component={AdminReport}
                exact
                guarded
                currentPermissions={currentPermissions}
                permitted={['ADMIN']}
                authenticated={authenticated}
                layout={MainLayout}
                path='/admin/report'
            />

            <RouteWithLayout
                component={AdminUnit}
                exact
                guarded
                currentPermissions={currentPermissions}
                permitted={['ADMIN']}
                authenticated={authenticated}
                layout={MainLayout}
                path='/admin/unit'
            />

            {/* User lists */}
            <RouteWithLayout component={TotalUsers} exact layout={MainLayout} path='/totalusers' />

            <RouteWithLayout
                component={Users}
                exact
                guarded
                currentPermissions={currentPermissions}
                permitted={['ADMIN', 'UNIT']}
                authenticated={authenticated}
                layout={MainLayout}
                path='/unit/users'
            />

            <RouteWithLayout
                component={UnitSettings}
                exact
                guarded
                currentPermissions={currentPermissions}
                permitted={['ADMIN', 'UNIT']}
                authenticated={authenticated}
                layout={MainLayout}
                path='/unit/profile'
            />
            <RouteWithLayout
                component={AccountNewConnection}
                currentPermissions={currentPermissions}
                permitted={['ADMIN', 'UNIT']}
                exact
                layout={MainLayout}
                path='/unit/plugins'
            />
            <RouteWithLayout
                component={AccountConnectionsBrowsePage}
                currentPermissions={currentPermissions}
                permitted={['ADMIN', 'UNIT']}
                exact
                layout={MainLayout}
                path='/unit/plugins/browse'
            />
            <RouteWithLayout
                component={AccountConnectPage}
                currentPermissions={currentPermissions}
                permitted={['ADMIN', 'UNIT']}
                exact
                layout={MainLayout}
                path='/unit/plugins/facebook'
            />
            <RouteWithLayout
                component={TargetingTemplates}
                currentPermissions={currentPermissions}
                permitted={['ADMIN', 'UNIT']}
                exact
                authenticated={authenticated}
                layout={MainLayout}
                path='/marketing/templates'
            />
            <RouteWithLayout
                component={UnitBudget}
                currentPermissions={currentPermissions}
                permitted={['ADMIN', 'UNIT']}
                exact
                authenticated={authenticated}
                layout={MainLayout}
                path='/marketing/budget'
            />
            {/* Authentication and user profile */}
            <RouteWithLayout component={AccountRecovery} exact layout={MinimalLayout} path='/recover' />
            <RouteWithLayout
                component={AccountRecoveryConfirmation}
                exact
                layout={MinimalLayout}
                path='/recover/confirmation'
            />
            <RouteWithLayout
                component={ResetPasswordView}
                layout={MinimalLayout}
                exact
                onResetPassword={onResetPassword}
                path='/reset-password/:token'
            />
            <RouteWithLayout component={InviteUserAccount} layout={MinimalLayout} exact path='/verify/:id' />
            <RouteWithLayout component={InvalidRecoveryToken} exact layout={MinimalLayout} path='/invalid-recovery' />
            <RouteWithLayout component={InvalidInviteToken} exact layout={MinimalLayout} path='/invalid-invite' />
            <RouteWithLayout component={SettingsView} exact layout={MainLayout} path='/settings' />
            <RouteWithLayout component={SignInView} exact layout={MinimalLayout} path='/sign-in' />
            <RouteWithLayout component={PowerBiSharedReport} exact layout={MinimalLayout} path='/shared' />

            {/* Policies */}
            <RouteWithLayout component={CookiePolicyView} exact layout={MainLayout} path='/cookie-policy' />

            {/* 404 Not Found */}
            <RouteWithLayout component={NotFoundView} exact layout={MinimalLayout} path='/not-found' />
            <Redirect to='/not-found' />
        </Switch>
    );
};
