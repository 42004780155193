import React, { useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import { useMutation, useQuery } from '@apollo/client';
import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { Delete, Refresh, Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { BlueHeaderContainer } from 'common/components';
import { GET_UNITS } from 'graphql/queries/user';
import useDebounce from 'hooks/useDebounce';

import { RESTORE_SOFT_DELETED_UNIT, SOFT_DELETE_UNIT } from './utils';

const useStyles = makeStyles((theme) => ({
    search: {
        maxWidth: 304
    },
    slimWidth: {
        width: '15%'
    },
    smallWidth: {
        width: '10%'
    },
    tinyWidth: {
        width: '5%'
    },
    tableCell: {
        padding: '4px',
        fontSize: '12px',
        lineHeight: '12px'
    },
    tableIcon: {
        padding: '4px'
    },
    tableRow: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    tableContainer: {
        overflowX: 'auto'
    }
}));

function AdminUnit() {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const debouncedSearchTerm = useDebounce(search, 500);

    const { data: unitsData, refetch: refetchUnits } = useQuery(GET_UNITS);
    const units = unitsData?.getUnits;
    const hasUnits = units && units.length;

    const [deleteUnit] = useMutation(SOFT_DELETE_UNIT, {
        onCompleted: () => {
            refetchUnits();
            enqueueSnackbar('Unit and its children removed', { variant: 'success' });
        },
        onError: (error) => {
            enqueueSnackbar('Could not remove unit and its children', { variant: 'error' });
        }
    });

    const [restoreUnit] = useMutation(RESTORE_SOFT_DELETED_UNIT, {
        onCompleted: () => {
            refetchUnits();
            enqueueSnackbar('Unit and its parents restored', { variant: 'success' });
        },
        onError: (error) => {
            enqueueSnackbar('Could not restore unit and its parents', { variant: 'error' });
        }
    });

    return (
        <BlueHeaderContainer title='Unit administration' padding={2}>
            <Grid container spacing={2}>
                <Grid container item xs={12} direction='row' justifyContent='space-between'>
                    <TextField
                        className={classes.search}
                        InputProps={{ endAdornment: <Search /> }}
                        fullWidth
                        label='Search'
                        variant='outlined'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                <TableCell className={clsx(classes.tableCell, classes.slimWidth)}>Id</TableCell>
                                <TableCell className={clsx(classes.tableCell, classes.slimWidth)}>Name</TableCell>
                                <TableCell className={clsx(classes.tableCell, classes.slimWidth)}>Parent Id</TableCell>
                                <TableCell className={clsx(classes.tableCell, classes.tinyWidth)} align='center'>
                                    No. users
                                </TableCell>
                                <TableCell className={clsx(classes.tableCell, classes.tinyWidth)} align='center'>
                                    No. reports
                                </TableCell>
                                <TableCell className={clsx(classes.tableCell, classes.tinyWidth)} align='center'>
                                    Demo data
                                </TableCell>
                                <TableCell className={clsx(classes.tableCell, classes.tinyWidth)} align='center'>
                                    Deleted
                                </TableCell>
                                <TableCell className={clsx(classes.tableCell, classes.smallWidth)} align='right'>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hasUnits &&
                                units
                                    .filter((unit) => {
                                        const regex = new RegExp(debouncedSearchTerm, 'i');

                                        return regex.test(unit.name);
                                    })
                                    .sort((a, b) => {
                                        if (a.parentId === null && b.parentId !== null) {
                                            return -1;
                                        }
                                        if (a.parentId !== null && b.parentId === null) {
                                            return 1;
                                        }
                                        if (a.parentId === null && b.parentId === null) {
                                            return 0;
                                        }

                                        return a.parentId.localeCompare(b.parentId);
                                    })
                                    .map((unit) => (
                                        <TableRow key={unit.id} className={classes.tableRow}>
                                            <TableCell className={clsx(classes.tableCell, classes.slimWidth)}>
                                                {unit.id}
                                            </TableCell>
                                            <TableCell className={clsx(classes.tableCell, classes.slimWidth)}>
                                                {unit.name}
                                            </TableCell>
                                            <TableCell className={clsx(classes.tableCell, classes.slimWidth)}>
                                                {unit.parentId}
                                            </TableCell>
                                            <TableCell
                                                className={clsx(classes.tableCell, classes.tinyWidth)}
                                                align='center'
                                            >
                                                {unit.users.length}
                                            </TableCell>
                                            <TableCell
                                                className={clsx(classes.tableCell, classes.tinyWidth)}
                                                align='center'
                                            >
                                                {unit.reports.length}
                                            </TableCell>
                                            <TableCell
                                                className={clsx(classes.tableCell, classes.tinyWidth)}
                                                align='center'
                                            >
                                                {unit.isDemo ? 'Yes' : 'No'}
                                            </TableCell>
                                            <TableCell
                                                className={clsx(classes.tableCell, classes.tinyWidth)}
                                                align='center'
                                            >
                                                {unit.isDeleted ? 'Yes' : 'No'}
                                            </TableCell>
                                            <TableCell
                                                className={clsx(classes.tableCell, classes.smallWidth)}
                                                align='right'
                                            >
                                                {unit.isDeleted && (
                                                    <IconButton
                                                        className={classes.tableIcon}
                                                        onClick={() => {
                                                            const confirmed = confirm(
                                                                'Are you sure you want to restore this unit? All of its parents will be restored as well.'
                                                            );

                                                            if (!confirmed) return;

                                                            restoreUnit({
                                                                variables: {
                                                                    id: unit.id
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <Refresh />
                                                    </IconButton>
                                                )}

                                                {!unit.isDeleted && (
                                                    <IconButton
                                                        className={classes.tableIcon}
                                                        onClick={() => {
                                                            const confirmed = confirm(
                                                                'Are you sure you want to remove this unit? All of its children will be removed as well.'
                                                            );

                                                            if (!confirmed) return;

                                                            deleteUnit({
                                                                variables: {
                                                                    id: unit.id
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </BlueHeaderContainer>
    );
}

export default AdminUnit;
