import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';

import { useLazyQuery } from '@apollo/client';
import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core';

import 'i18n';

import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { GET_POWER_BI_REPORT_CONFIG_FOR_UNIT } from 'graphql/queries/unit';

import './report.css';

const DEFAULT_REPORT_CONFIG = {
    type: 'report',
    embedUrl: undefined,
    accessToken: undefined,
    tokenType: models.TokenType.Embed,
    settings: {
        panes: {
            filters: {
                expanded: false,
                visible: false
            }
        },
        navContentPaneEnabled: false,
        persistentFiltersEnabled: true,
        background: models.BackgroundType.Transparent
    }
};

export const PowerBiReport = (props) => {
    const { reportId, groupId } = props.match.params;

    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const activeReport = useMemo(() => {
        return (
            activeUnit?.reports?.find(
                (report) => report.pbiReportId === reportId && report.pbiWorkspaceId === groupId
            ) || {}
        );
    }, [activeUnit, reportId, groupId]);

    const bookmarksManager = useRef({});
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [isSuccess, setIsSuccess] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('Retrieving and analyzing data...');

    const [reportConfig, setReportConfig] = useState(DEFAULT_REPORT_CONFIG);

    const [getUnitPowerBIReportConfig] = useLazyQuery(GET_POWER_BI_REPORT_CONFIG_FOR_UNIT, {
        onError: (error) => {
            console.error(error);
            enqueueSnackbar(t('Report could not be loaded'), { variant: 'error' });
            setIsSuccess(false);
            setIsLoading(false);
        },
        onCompleted: (res) => {
            if (res?.getPowerBIReportConfig && res?.getPowerBIReportConfig?.embeddedToken) {
                const { embeddedToken } = res.getPowerBIReportConfig;
                const { token, embedUrl, filters, isEditable, settings } = embeddedToken;

                let config = {
                    accessToken: token,
                    embedUrl,
                    filters: filters.map(
                        ({ schema, target, operator, values, filterType, requireSingleSelection }) => ({
                            $schema: schema,
                            target,
                            operator,
                            values,
                            filterType,
                            requireSingleSelection
                        })
                    ),
                    settings: {
                        ...settings,
                        navContentPaneEnabled: false
                    }
                };

                if (isMobile) {
                    config = {
                        ...config,
                        settings: { ...config?.settings, layoutType: models.LayoutType.MobilePortrait }
                    };
                }

                if (isEditable) {
                    config = {
                        ...config,
                        permissions: models.Permissions.ReadWrite,
                        viewMode: models.ViewMode.Edit
                    };
                }

                setReportConfig((prev) => ({
                    ...prev,
                    ...config
                }));
                setIsSuccess(true);
            } else {
                setIsSuccess(false);
                setIsLoading(false);
            }
        }
    });

    useEffect(() => {
        if (activeReport && activeUnit) {
            setIsLoading(true);
            setLoadingText('Retrieving and analyzing data...');

            getUnitPowerBIReportConfig({
                variables: {
                    mangled: false,
                    id: activeReport.id,
                    reportId: activeReport.pbiReportId,
                    groupId: activeReport.pbiWorkspaceId,
                    unitId: activeUnit.id,
                    locale: i18n?.language
                }
            });
        }
    }, [getUnitPowerBIReportConfig, activeReport, activeUnit, i18n]);

    return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
            {isLoading && (
                <Box component={Backdrop} sx={{ color: 'white' }} open>
                    <Box>
                        <CircularProgress color='primary' size='64px' />

                        <Typography variant='h5' color='inherit' style={{ marginTop: 16 }}>
                            {t(loadingText)}
                        </Typography>
                    </Box>
                </Box>
            )}
            {isSuccess && (
                <Box sx={{ visibility: isLoading ? 'hidden' : 'visible' }}>
                    <PowerBIEmbed
                        key={reportId + groupId}
                        embedConfig={reportConfig}
                        eventHandlers={
                            new Map([
                                [
                                    'loaded',
                                    function () {
                                        /* eslint-disable-next-line */
                                        console.log('Report has loaded');
                                        setLoadingText('Showing report...');

                                        setTimeout(() => {
                                            setIsLoading(false);
                                        }, 3000);
                                    }
                                ],
                                [
                                    'rendered',
                                    async function () {
                                        /* eslint-disable-next-line */
                                        console.log('Report has rendered');
                                        setLoadingText('Crunching datasets...');
                                    }
                                ],
                                [
                                    'error',
                                    function (event) {
                                        if (event) {
                                            /* eslint-disable-next-line */
                                            console.error(event.detail);
                                        }
                                    }
                                ]
                            ])
                        }
                        cssClassName={'report-style-class'}
                        getEmbeddedComponent={(report) => {
                            if (report) {
                                bookmarksManager.current = report?.bookmarksManager;
                            }
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

PowerBiReport.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            groupId: PropTypes.string,
            reportId: PropTypes.string
        })
    })
};
