import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { useMutation } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormLabel,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import { UnitSelector } from 'common/components';

import { CREATE_REPORT } from './utils';

function CreateReport({ categories, units, refetchReports }) {
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [createReportInput, setCreateReportInput] = useState({
        name: '',
        description: '',
        isReusable: false,
        isEditable: false,
        sequence: 1,
        pbiWorkspaceId: '',
        pbiReportId: '',
        categoryId: '',
        units: []
    });

    const [createReport] = useMutation(CREATE_REPORT, {
        onCompleted: (data) => {
            const createdReport = data?.createReport;

            console.info(createdReport);

            if (createdReport?.id) {
                refetchReports();
                enqueueSnackbar('Report created', { variant: 'success' });
                handleClose();
            } else {
                enqueueSnackbar('Could not create report', { variant: 'error' });
            }
        }
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        if (!createReportInput.name) {
            enqueueSnackbar({ message: 'Name is required', variant: 'error' });

            return;
        }

        if (!createReportInput.pbiWorkspaceId) {
            enqueueSnackbar({ message: 'Power BI workspace ID is required', variant: 'error' });

            return;
        }

        if (!createReportInput.pbiReportId) {
            enqueueSnackbar({ message: 'Power BI report ID is required', variant: 'error' });

            return;
        }

        if (!createReportInput.categoryId) {
            enqueueSnackbar({ message: 'Category is required', variant: 'error' });

            return;
        }

        await createReport({
            variables: {
                input: createReportInput
            }
        });
    };

    return (
        <React.Fragment>
            <Button variant='contained' color='primary' onClick={handleOpen}>
                Create report
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Typography variant='h6' color='primary'>
                            Create Report
                        </Typography>
                        <IconButton
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <Clear />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={8}>
                                <TextField
                                    name='name'
                                    fullWidth
                                    value={createReportInput.name}
                                    onChange={(e) =>
                                        setCreateReportInput((prev) => ({ ...prev, name: e.target.value }))
                                    }
                                    label='Report name'
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    name='sequence'
                                    type='number'
                                    label='Sorting priority'
                                    value={createReportInput.sequence}
                                    onChange={(e) =>
                                        setCreateReportInput((prev) => ({
                                            ...prev,
                                            sequence: parseInt(e.target.value) || 1
                                        }))
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Description (optional)'
                                name='description'
                                value={createReportInput.description}
                                onChange={(e) =>
                                    setCreateReportInput((prev) => ({ ...prev, description: e.target.value }))
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Power BI workspace ID'
                                name='pbiWorkspaceId'
                                value={createReportInput.pbiWorkspaceId}
                                onChange={(e) =>
                                    setCreateReportInput((prev) => ({ ...prev, pbiWorkspaceId: e.target.value }))
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Power BI report ID'
                                name='pbiReportId'
                                value={createReportInput.pbiReportId}
                                onChange={(e) =>
                                    setCreateReportInput((prev) => ({ ...prev, pbiReportId: e.target.value }))
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                options={categories}
                                getOptionLabel={(option) => option.name}
                                onChange={(_, newValue) => {
                                    setCreateReportInput((prev) => ({ ...prev, categoryId: newValue.id }));
                                }}
                                renderInput={(params) => <TextField {...params} label='Category' />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormLabel>Grant access to units</FormLabel>
                            <UnitSelector
                                setSelectedUnits={(units) => setCreateReportInput((prev) => ({ ...prev, units }))}
                                selectedUnits={createReportInput.units}
                                units={units}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color='primary' variant='contained'>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

CreateReport.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            isActive: PropTypes.bool.isRequired
        })
    ).isRequired,
    units: PropTypes.any,
    refetchReports: PropTypes.func.isRequired
};

export default CreateReport;
