import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
    Box,
    Chip,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { UnitContext } from '../unit.provider';
import { DATA_SOURCES } from './utils';

const useStyles = makeStyles((theme) => ({
    mediumWidth: {
        width: '24%'
    },
    tableCell: {
        padding: '4px',
        fontSize: '12px',
        lineHeight: '12px'
    },
    tableIcon: {
        padding: '4px'
    },
    tableRow: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    slimTextField: {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: '4px',
            paddingRight: '39px'
        }
    }
}));

function DataSourceSettings() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { editingUnit, setEditingUnit, setIsEdited } = useContext(UnitContext);

    const [focusedField, setFocusedField] = useState();

    return (
        <Box px={2} py={2}>
            <Typography variant='h5' color='primary' style={{ marginBottom: 16 }}>
                {t('Data sources')}
            </Typography>

            <Table>
                <TableHead>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={clsx(classes.tableCell, classes.mediumWidth)}>
                            {t('Source name')}
                        </TableCell>
                        <TableCell className={clsx(classes.tableCell)}>{t('Source IDs')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {DATA_SOURCES.map((source) => (
                        <TableRow key={source.key} className={classes.tableRow}>
                            <TableCell className={clsx(classes.tableCell, classes.mediumWidth)}>
                                {source.name}
                            </TableCell>
                            <TableCell className={clsx(classes.tableCell)}>
                                <Autocomplete
                                    freeSolo
                                    multiple
                                    options={[]}
                                    onFocus={() => setFocusedField(source.key)}
                                    onBlur={() => setFocusedField(undefined)}
                                    value={editingUnit?.dataSources?.[source.key] || []}
                                    onChange={(_, newValue) => {
                                        setIsEdited(true);

                                        setEditingUnit((prev) => ({
                                            ...prev,
                                            dataSources: { ...prev.dataSources, [source.key]: newValue }
                                        }));
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                key={index}
                                                variant='outlined'
                                                label={option}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={classes.slimTextField}
                                            variant='outlined'
                                            helperText={
                                                focusedField === source.key
                                                    ? t('Press ENTER to add a new source ID')
                                                    : undefined
                                            }
                                        />
                                    )}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
}

export default DataSourceSettings;
