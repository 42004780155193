import gql from 'graphql-tag';

export const LIST_REPORTS = gql`
    query {
        listReports {
            id
            name
            description
            isReusable
            pbiWorkspaceId
            pbiReportId
            sequence
            isEditable
            category
            units
        }
    }
`;

export const LIST_CATEGORIES = gql`
    query {
        listCategories {
            id
            name
            isActive
            sequence
        }
    }
`;

export const CREATE_REPORT = gql`
    mutation createReport($input: UpdateReportInput) {
        createReport(input: $input) {
            id
            name
            isReusable
            pbiWorkspaceId
            pbiReportId
            sequence
            isEditable
        }
    }
`;

export const UPDATE_REPORT = gql`
    mutation updateReport($id: ID, $input: UpdateReportInput) {
        updateReport(id: $id, input: $input) {
            id
            name
            isReusable
            pbiWorkspaceId
            pbiReportId
            sequence
            isEditable
        }
    }
`;

export const DELETE_REPORT = gql`
    mutation deleteReport($id: ID) {
        deleteReport(id: $id) {
            id
            name
            isReusable
            pbiWorkspaceId
            pbiReportId
            sequence
            isEditable
        }
    }
`;
