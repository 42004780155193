import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';

import { ThemeProvider } from '@material-ui/styles';
import { LicenseInfo } from '@material-ui/x-grid';

import './i18n';

import { GA } from 'common/components/GoogleAnalytics/GoogleAnalytics.jsx';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from 'i18n/utils';

import { Apollo, ErrorBoundary, InitialUserLoading } from './common/components';
import AuthProvider from './context/AuthContext';
import { Provider as CampaignsProvider } from './context/campaigns/CampaignsConext';
import { Provider as FeedProvider } from './context/FeedContext';
import { Provider as ProductFilterProvider } from './context/ProductFilter/ProductFilterContext';
import { Provider as ProductsProvider } from './context/products/ProductsContext';
import { Provider as ActiveUnitProvider } from './context/units/activeUnitContext';
import theme from './layouts/theme';
import { Routes } from './views';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-block-ui/style.css';

const currentLang = window.localStorage.getItem('i18nextLng');
const lang = SUPPORTED_LANGUAGES.includes(currentLang) ? currentLang : DEFAULT_LANGUAGE;
window.localStorage.setItem('i18nextLng', lang);

LicenseInfo.setLicenseKey(
    'a90aa1d08345ef452a91143af89d3217T1JERVI6MjYzODcsRVhQSVJZPTE2NTYwOTY0ODQwMDAsS0VZVkVSU0lPTj0x'
);

const browserHistory = createBrowserHistory();
const App = () => (
    <ActiveUnitProvider>
        <ProductsProvider>
            <CampaignsProvider>
                <ProductFilterProvider>
                    <FeedProvider>
                        <AuthProvider>
                            <Apollo>
                                <ThemeProvider theme={theme}>
                                    <SnackbarProvider maxSnack={5}>
                                        <InitialUserLoading>
                                            <Router history={browserHistory}>
                                                {GA.init() && (
                                                    // eslint-disable-next-line react/jsx-pascal-case
                                                    <GA.RouteTracker />
                                                )}
                                                <ErrorBoundary>
                                                    <Routes />
                                                </ErrorBoundary>
                                            </Router>
                                        </InitialUserLoading>
                                    </SnackbarProvider>
                                </ThemeProvider>
                            </Apollo>
                        </AuthProvider>
                    </FeedProvider>
                </ProductFilterProvider>
            </CampaignsProvider>
        </ProductsProvider>
    </ActiveUnitProvider>
);

export default App;
