import React, { useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import { useMutation, useQuery } from '@apollo/client';
import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { Delete, Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { BlueHeaderContainer } from 'common/components';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import useDebounce from 'hooks/useDebounce';

import CreateReport from './CreateReport';
import EditReport from './EditReport';
import { DELETE_REPORT, LIST_CATEGORIES, LIST_REPORTS } from './utils';

const useStyles = makeStyles((theme) => ({
    search: {
        maxWidth: 304
    },
    slimWidth: {
        width: '15%'
    },
    smallWidth: {
        width: '10%'
    },
    tinyWidth: {
        width: '5%'
    },
    tableCell: {
        padding: '4px',
        fontSize: '12px',
        lineHeight: '12px'
    },
    tableIcon: {
        padding: '4px'
    },
    tableRow: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    tableContainer: {
        overflowX: 'auto'
    }
}));

function AdminReport() {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const debouncedSearchTerm = useDebounce(search, 500);

    const {
        state: { units }
    } = useContext(activeUnitContext);

    const { data: categoryData } = useQuery(LIST_CATEGORIES);
    const categories = categoryData?.listCategories;
    const hasCategories = categories && categories.length;

    const { data: reportData, refetch: refetchReports } = useQuery(LIST_REPORTS);
    const reports = reportData?.listReports;
    const hasReports = reports && reports.length;

    const [deleteReport] = useMutation(DELETE_REPORT, {
        onCompleted: (data) => {
            const deletedReport = data?.deleteReport;

            console.info(deletedReport);

            if (deletedReport?.id) {
                refetchReports();
                enqueueSnackbar('Report deleted', { variant: 'success' });
            } else {
                enqueueSnackbar('Could not delete report', { variant: 'error' });
            }
        }
    });

    return (
        <BlueHeaderContainer title='Report administration' padding={2}>
            <Grid container spacing={2}>
                <Grid container item xs={12} direction='row' justifyContent='space-between'>
                    <TextField
                        className={classes.search}
                        InputProps={{ endAdornment: <Search /> }}
                        fullWidth
                        label='Search'
                        variant='outlined'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />

                    {hasCategories ? (
                        <CreateReport categories={categories} units={units} refetchReports={refetchReports} />
                    ) : null}
                </Grid>
                <Grid item xs={12} className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                <TableCell className={clsx(classes.tableCell, classes.slimWidth)}>Name</TableCell>
                                <TableCell className={clsx(classes.tableCell, classes.slimWidth)}>
                                    Description
                                </TableCell>
                                <TableCell className={classes.tableCell}>PowerBI Workspace</TableCell>
                                <TableCell className={classes.tableCell}>PowerBI Report</TableCell>
                                <TableCell className={clsx(classes.tableCell, classes.tinyWidth)}>
                                    Sort priority
                                </TableCell>
                                <TableCell className={clsx(classes.tableCell, classes.smallWidth)} align='right'>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hasReports &&
                                reports
                                    .filter((report) => {
                                        const regex = new RegExp(debouncedSearchTerm, 'i');

                                        return regex.test(report.name);
                                    })
                                    .map((report) => (
                                        <TableRow key={report.id} className={classes.tableRow}>
                                            <TableCell className={clsx(classes.tableCell, classes.slimWidth)}>
                                                {report.name}
                                            </TableCell>
                                            <TableCell className={clsx(classes.tableCell, classes.slimWidth)}>
                                                {report.description}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>{report.pbiWorkspaceId}</TableCell>
                                            <TableCell className={classes.tableCell}>{report.pbiReportId}</TableCell>
                                            <TableCell className={clsx(classes.tableCell, classes.tinyWidth)}>
                                                {report.sequence}
                                            </TableCell>
                                            <TableCell
                                                className={clsx(classes.tableCell, classes.smallWidth)}
                                                align='right'
                                            >
                                                <EditReport
                                                    report={report}
                                                    categories={categories}
                                                    units={units}
                                                    refetchReports={refetchReports}
                                                />
                                                <IconButton
                                                    className={classes.tableIcon}
                                                    onClick={() => {
                                                        const confirmed = confirm(
                                                            'Are you sure you want to delete this report?'
                                                        );

                                                        if (confirmed) {
                                                            deleteReport({
                                                                variables: {
                                                                    id: report.id
                                                                }
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </BlueHeaderContainer>
    );
}

export default AdminReport;
