import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { useMutation } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormLabel,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import { UnitSelector } from 'common/components';

import { UPDATE_REPORT } from './utils';

function EditReportDialog({ report, onClose, categories, units, refetchReports }) {
    const { enqueueSnackbar } = useSnackbar();

    const [editReportInput, setEditReportInput] = useState({
        name: report.name,
        description: report.description,
        isReusable: report.isReusable || false,
        isEditable: report.isEditable || false,
        sequence: report.sequence,
        pbiWorkspaceId: report.pbiWorkspaceId,
        pbiReportId: report.pbiReportId,
        categoryId: report.category,
        units: report.units
    });

    const [updateReport] = useMutation(UPDATE_REPORT, {
        onCompleted: (data) => {
            const editedReport = data?.updateReport;

            console.info(editedReport);

            if (editedReport?.id) {
                refetchReports();
                enqueueSnackbar('Report edited', { variant: 'success' });
                onClose();
            } else {
                enqueueSnackbar('Could not edit report', { variant: 'error' });
            }
        }
    });

    const handleSave = async () => {
        if (!editReportInput.name) {
            enqueueSnackbar({ message: 'Name is required', variant: 'error' });

            return;
        }

        if (!editReportInput.pbiWorkspaceId) {
            enqueueSnackbar({ message: 'Power BI workspace ID is required', variant: 'error' });

            return;
        }

        if (!editReportInput.pbiReportId) {
            enqueueSnackbar({ message: 'Power BI report ID is required', variant: 'error' });

            return;
        }

        if (!editReportInput.categoryId) {
            enqueueSnackbar({ message: 'Category is required', variant: 'error' });

            return;
        }

        await updateReport({
            variables: {
                id: report.id,
                input: editReportInput
            }
        });
    };

    return (
        <React.Fragment>
            <Dialog open onClose={onClose}>
                <DialogTitle>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Typography variant='h6' color='primary'>
                            Create Report
                        </Typography>
                        <IconButton onClick={onClose}>
                            <Clear />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={8}>
                                <TextField
                                    name='name'
                                    fullWidth
                                    value={editReportInput.name}
                                    onChange={(e) => setEditReportInput((prev) => ({ ...prev, name: e.target.value }))}
                                    label='Report name'
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    name='sequence'
                                    type='number'
                                    label='Sorting priority'
                                    value={editReportInput.sequence}
                                    onChange={(e) =>
                                        setEditReportInput((prev) => ({
                                            ...prev,
                                            sequence: parseInt(e.target.value) || 1
                                        }))
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Description (optional)'
                                name='description'
                                value={editReportInput.description}
                                onChange={(e) =>
                                    setEditReportInput((prev) => ({ ...prev, description: e.target.value }))
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Power BI workspace ID'
                                name='pbiWorkspaceId'
                                value={editReportInput.pbiWorkspaceId}
                                onChange={(e) =>
                                    setEditReportInput((prev) => ({ ...prev, pbiWorkspaceId: e.target.value }))
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Power BI report ID'
                                name='pbiReportId'
                                value={editReportInput.pbiReportId}
                                onChange={(e) =>
                                    setEditReportInput((prev) => ({ ...prev, pbiReportId: e.target.value }))
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                options={categories}
                                getOptionLabel={(option) => option.name}
                                value={
                                    categories.find((category) => category.id === editReportInput.categoryId) || null
                                }
                                onChange={(_, newValue) => {
                                    setEditReportInput((prev) => ({ ...prev, categoryId: newValue.id }));
                                }}
                                renderInput={(params) => <TextField {...params} label='Category' />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormLabel>Grant access to units</FormLabel>
                            <UnitSelector
                                setSelectedUnits={(units) => setEditReportInput((prev) => ({ ...prev, units }))}
                                selectedUnits={editReportInput.units}
                                units={units}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color='primary' variant='contained'>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

EditReportDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    report: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        isReusable: PropTypes.bool.isRequired,
        isEditable: PropTypes.bool.isRequired,
        sequence: PropTypes.number.isRequired,
        pbiWorkspaceId: PropTypes.string.isRequired,
        pbiReportId: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        units: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            isActive: PropTypes.bool.isRequired
        })
    ).isRequired,
    units: PropTypes.any,
    refetchReports: PropTypes.func.isRequired
};

export default EditReportDialog;
