import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
    Grid,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { BlueExpansionPanel } from 'common/components';

import { UnitContext } from '../unit.provider';
import AddReport from './AddReport';

const useStyles = makeStyles((theme) => ({
    smallWidth: {
        width: '10%'
    },
    tableCell: {
        padding: '4px',
        fontSize: '12px',
        lineHeight: '12px'
    },
    tableIcon: {
        padding: '4px'
    },
    tableRow: {
        borderBottom: `1px solid ${theme.palette.divider}`
    }
}));

const ReportSettings = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { categories, editingUnit, setEditingUnit, setIsEdited } = useContext(UnitContext);

    const reportCategories = categories?.filter((category) => category?.reports?.length) || [];

    return (
        <Grid container direction={'column'} spacing={4} style={{ maxWidth: 1152, padding: 40 }}>
            <Typography variant='h5' color='primary' style={{ marginBottom: 16 }}>
                {t('PowerAnalytics report settings')}
            </Typography>

            <Grid container justifyContent='space-between' style={{ marginBottom: 16 }}>
                <Grid item>
                    <Typography variant='h6' color='primary'>
                        {t('Reports')}
                    </Typography>
                </Grid>
                <Grid item>
                    {editingUnit ? (
                        <AddReport
                            existingReports={editingUnit.reports}
                            reportCategories={reportCategories}
                            addReports={(newReports) => {
                                setEditingUnit((prev) => ({
                                    ...prev,
                                    reports: [...prev.reports, ...newReports]
                                }));
                                setIsEdited(true);
                            }}
                        />
                    ) : null}
                </Grid>
            </Grid>

            {reportCategories.map((category) => {
                const hasActiveReportInCategory = category.reports.some((report) =>
                    editingUnit?.reports?.find((rep) => rep.id === report.id)
                );
                if (!hasActiveReportInCategory) return null;

                return (
                    <BlueExpansionPanel
                        key={category.id}
                        title={category.name}
                        defaultExpanded
                        style={{ marginBottom: 16, overflow: 'auto' }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <TableCell className={clsx(classes.tableCell)}>{t('Report name')}</TableCell>
                                    <TableCell align='center' className={clsx(classes.tableCell)}>
                                        {t('Created date')}
                                    </TableCell>
                                    <TableCell align='center' className={clsx(classes.tableCell)}>
                                        {t('Status')}
                                    </TableCell>
                                    <TableCell className={clsx(classes.tableCell)} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {category.reports.map((report) => {
                                    const addedReport = editingUnit?.reports?.find((rep) => rep.id === report.id);
                                    if (!addedReport) return null;

                                    return (
                                        <TableRow key={`report-${report.id}`} className={classes.tableRow}>
                                            <TableCell className={clsx(classes.tableCell)}>{report.name}</TableCell>
                                            <TableCell align='center' className={clsx(classes.tableCell)}>
                                                -
                                            </TableCell>
                                            <TableCell align='center' className={clsx(classes.tableCell)}>
                                                -
                                            </TableCell>
                                            <TableCell
                                                align='right'
                                                className={clsx(classes.tableCell, classes.smallWidth)}
                                            >
                                                <IconButton
                                                    className={classes.tableIcon}
                                                    onClick={() => {
                                                        setEditingUnit((prev) => ({
                                                            ...prev,
                                                            reports: prev?.reports?.filter(
                                                                (rep) => rep.id !== report.id
                                                            )
                                                        }));

                                                        setIsEdited(true);
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </BlueExpansionPanel>
                );
            })}
        </Grid>
    );
};

export default ReportSettings;
