import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import { useLazyQuery } from '@apollo/client';

import { AuthContext } from 'context/AuthContext';
import { Context as ActiveUnitContext } from 'context/units/activeUnitContext';
import { ME } from 'graphql/queries/user';

export const InitialUserLoading = ({ children }) => {
    const { authenticated, setAuthenticated } = useContext(AuthContext);
    const { setAllUnits, setActiveUnit, setMePermissions } = useContext(ActiveUnitContext);

    const setUnitState = ({ me }) => {
        if (authenticated && me) {
            setAllUnits(me.units ? cloneDeep(me.units) : []);
            setMePermissions(me.userPermissions ? cloneDeep(me.userPermissions) : []);
            const activeUnit = cloneDeep(
                me.activeUnit ? me.units.find((unit) => unit.id === me.activeUnit) : me.units[0]
            );
            setActiveUnit(activeUnit);
        } else {
            setAuthenticated(null);
        }
    };

    const [getLazyMe] = useLazyQuery(ME, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => setUnitState(data)
    });

    useEffect(() => {
        if (authenticated) {
            getLazyMe();
        }
    }, [authenticated, getLazyMe]);

    return <React.Fragment>{children}</React.Fragment>;
};

InitialUserLoading.propTypes = {
    children: PropTypes.element
};
