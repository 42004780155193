import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMutation } from '@apollo/client';
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Tooltip,
    Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Add, Close } from '@material-ui/icons';
import AddCircle from '@material-ui/icons/AddCircle';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { Context as ActiveUnitContext } from 'context/units/activeUnitContext';
import { INVITE_USERS } from 'graphql/mutations/user';
import { logError } from 'helpers/error';
import { switchFocusKey, validEmail } from 'helpers/validators';

const useStyles = makeStyles((theme) => ({
    headerTitle: {
        marginRight: theme.spacing(3.75),
        marginTop: '5px'
    },

    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        display: 'flex',
        alignItems: 'center'
    }
}));

export const NewUserInvite = ({ unitId, unitName, refetch }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [error, setError] = useState(false);
    const [helper, setHelper] = useState('');
    const [open, setOpen] = useState(false);
    const [inviteSendVisibility, setInviteSendVisibility] = useState(false);
    const [emails, setEmails] = useState([]);
    const [emailString, setEmailString] = useState('');

    const [updatedFeedFields] = useMutation(INVITE_USERS, {
        onCompleted: () => {
            refetch();
            setOpen(false);
        },
        onError: (err) => logError(err)
    });

    const {
        state: { units }
    } = useContext(ActiveUnitContext);

    const mailUnits = useMemo(() => {
        if (emailString && emailString.length) {
            return units.flatMap((unit) => {
                const user = unit.users?.find((user) => user.email === emailString);

                if (user) {
                    return [unit.name];
                }

                return [];
            });
        }

        return [];
    }, [emailString, units]);

    useEffect(() => {
        const isEmail = validEmail(emailString);
        const emailExists = emails.some((currentEmail) => currentEmail.toLowerCase() === emailString);

        if (!isEmail) {
            setHelper(t('Is not a valid email').toLowerCase());
        } else if (emailExists) {
            setHelper(t('This email address is already added'));
        }
        setError(!isEmail || emailExists);
        setInviteSendVisibility(!(isEmail || emails.length));
    }, [emailString, emails, t, setInviteSendVisibility]);

    const handleDelete = (item) => {
        setEmails(emails.filter((currentItem) => currentItem !== item));
    };

    const handleKeyDown = (e) => {
        if (!error && switchFocusKey(e)) {
            setEmails([...emails, emailString]);
            setEmailString('');
            setError(false);
        }
    };

    const handleChange = (e) => {
        setEmailString(e.target.value.toLowerCase());
    };

    const handleAdd = (e) => {
        e.preventDefault();

        if (!error) {
            setEmails([...emails, emailString]);
            setEmailString('');
            setError(false);
        }
    };

    const handlePaste = (e) => {
        const existingText = e.target.value,
            selectStart = e.target.selectionStart,
            selectEnd = e.target.selectionEnd;

        e.clipboardData.items[0].getAsString((str) => {
            const newText =
                existingText.substring(0, selectStart) + str + existingText.substring(selectEnd, existingText.length);
            str = newText.replace(/\s+/g, '');
            setEmailString(str);
            const isEmail = validEmail(str);
            if (isEmail) {
                setEmails([...emails, str]);
                setEmailString('');
                setError(false);
            }
        });
    };

    const handleSendInvite = () => {
        const emailExists = emails.some((currentEmail) => currentEmail.toLowerCase() === emailString);
        const isEmail = validEmail(emailString);
        let mailsToBeSent = [...emails];

        if (!emailExists && isEmail) {
            mailsToBeSent = [...mailsToBeSent, emailString];
        }

        updatedFeedFields({
            variables: { input: { emails: mailsToBeSent, unitId: unitId } }
        });
    };

    const unitsText = mailUnits?.length ? `${t('Warning: email is already registered')}` : undefined;

    return (
        <div>
            <Button color='primary' startIcon={<Add />} onClick={() => setOpen(true)}>
                {t('Create new user')}
            </Button>

            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby='customized-dialog-title'
                open={open}
                fullWidth
                maxWidth='sm'
            >
                <DialogTitle disableTypography id='customized-dialog-title'>
                    <Typography className={classes.headerTitle}>
                        {t('Add users to')} {unitName}
                    </Typography>
                    <IconButton aria-label='close' className={classes.closeButton} onClick={() => setOpen(false)}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers style={{ maxHeight: 300 }}>
                    <TextField
                        variant='filled'
                        value={emailString}
                        fullWidth
                        label={t('E-mail address')}
                        onChange={handleChange}
                        onCut={handleChange}
                        onKeyDown={handleKeyDown}
                        error={!!(emailString.length && error)}
                        onPaste={handlePaste}
                        helperText={emailString.length && error ? helper : unitsText}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    {!error && (
                                        <Tooltip title={t('Add to mail list')}>
                                            <IconButton
                                                disabled={error}
                                                style={{ color: grey[400] }}
                                                onClick={handleAdd}
                                            >
                                                <AddCircle />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </InputAdornment>
                            )
                        }}
                    />
                    <Box mt={2}>
                        {emails.length > 0 &&
                            emails.map((email) => (
                                <div key={email.id} style={{ marginBottom: 4, marginTop: 2 }}>
                                    <Chip
                                        icon={<MailOutlineIcon />}
                                        label={email}
                                        onDelete={() => handleDelete(email)}
                                    ></Chip>
                                </div>
                            ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={inviteSendVisibility}
                        size='medium'
                        fullWidth
                        onClick={() => {
                            handleSendInvite();
                            refetch();
                            setOpen(false);
                            setEmails([]);
                            setEmailString('');
                        }}
                        color='primary'
                        variant='contained'
                    >
                        {t('user.addUsers')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

NewUserInvite.propTypes = {
    unitId: PropTypes.string,
    unitName: PropTypes.string,
    refetch: PropTypes.func
};
