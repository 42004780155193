import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    Switch,
    Typography,
    Zoom
} from '@material-ui/core';

import { BlueHeaderContainer } from 'common/components';
import DatePickerWithRange from 'common/components/Pickers/Date';
import { daysEnum } from 'helpers/datetime';

import AdScheduleTimeSelector from './AdScheduleTimeSelector';

const useStyles = makeStyles((theme) => ({
    outlinedPrimary: {
        border: 0,
        borderLeft: '1px solid',
        borderColor: theme.palette.divider,
        color: theme.palette.text.primary,

        '&:first-of-type': {
            borderLeft: 'none'
        }
    },
    containedPrimary: {
        border: 0,
        borderLeft: '1px solid',
        borderColor: theme.palette.divider,
        padding: '5px 15px 5px 15px',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
        boxShadow: 'none',

        '&:first-of-type': {
            borderLeft: 'none'
        }
    },
    buttonGroup: {
        border: '1px solid',
        borderColor: theme.palette.divider,
        height: theme.size.elementHeight.normal
    }
}));

export const AdScheduling = ({
    continuous,
    dateRange,
    setDateRange,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    days,
    setDays,
    scheduleType,
    setScheduleType
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const toggleDay = (day) => () => {
        const dayIndex = days.findIndex((d) => d.day === day);
        if (dayIndex >= 0) days[dayIndex]['enabled'] = !days[dayIndex].enabled;
        else days.push({ day: day, enabled: true });
        setDays(days);
    };

    const handleSetDayStartTime = (time, day) => {
        const dayIndex = days.findIndex((d) => d.day === day);
        days[dayIndex]['startTime'] = time;
        setDays(days);
    };

    const handleSetDayEndTime = (time, day) => {
        const dayIndex = days.findIndex((d) => d.day === day);
        days[dayIndex]['endTime'] = time;
        setDays(days);
    };

    const sortedDays = () => {
        const sortedDays = [];
        if (!specifyDays) {
            return sortedDays;
        }

        Object.values(daysEnum).forEach((day) => {
            days.forEach((d) => {
                if (d.day === day && d.enabled) sortedDays.push(d);
            });
        });

        return sortedDays;
    };

    const [specifyDays, setSpecifyDays] = useState(days.length > 0);

    useEffect(() => {
        if (!days.some((day) => day.enabled)) {
            setSpecifyDays(false);
        }
    }, [days]);

    return (
        <BlueHeaderContainer title={t('Schedule')} padding={3}>
            <Grid container item xs={12} sm={10} md={7} direction='column'>
                <FormControl component='fieldset'>
                    <RadioGroup
                        row
                        aria-label='schedule-type'
                        name='scheduleType'
                        value={scheduleType}
                        onChange={(e) => setScheduleType(e.target.value)}
                    >
                        <FormControlLabel
                            value='startEnd'
                            control={<Radio color='primary' />}
                            label={t('Start and end date')}
                            labelPlacement='end'
                        />
                        <FormControlLabel
                            value='continuous'
                            control={<Radio color='primary' />}
                            label={t('Run ads until campaign is stopped')}
                            labelPlacement='end'
                        />
                    </RadioGroup>
                </FormControl>
                <DatePickerWithRange
                    continuous={continuous}
                    dateRange={continuous ? [dateRange[0], null] : dateRange}
                    setDateRange={setDateRange}
                />
                <Box paddingY={1} display='flex' alignItems='center'>
                    <Switch
                        color='primary'
                        checked={specifyDays}
                        onChange={() => {
                            setSpecifyDays(!specifyDays);
                        }}
                    />

                    <Typography variant='body2'>{t('Specify days')}</Typography>
                </Box>
                <Zoom in={specifyDays} appear={false}>
                    <Box>
                        <ButtonGroup
                            color='primary'
                            className={classes.buttonGroup}
                            disableElevation
                            aria-label='primary button group'
                        >
                            {Object.entires(daysEnum).map((enumDay) => (
                                <Button
                                    classes={{
                                        outlinedPrimary: classes.outlinedPrimary,
                                        containedPrimary: classes.containedPrimary
                                    }}
                                    key={`day-button-${enumDay[0]}`}
                                    title={t(enumDay[0])}
                                    onClick={toggleDay(enumDay)}
                                    variant={
                                        days.some((day) => day.day === enumDay[1] && day.enabled)
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                >
                                    {t(enumDay[0]).charAt(0)}
                                </Button>
                            ))}
                        </ButtonGroup>
                        {sortedDays().map((day) => (
                            <Box marginY={1} key={'time_selector_' + day.day}>
                                <AdScheduleTimeSelector
                                    startTime={day.startTime}
                                    defaultStartTime={startTime}
                                    setStartTime={handleSetDayStartTime}
                                    endTime={day.endTime}
                                    defaultEndTime={endTime}
                                    setEndTime={handleSetDayEndTime}
                                    day={day.day}
                                />
                            </Box>
                        ))}
                    </Box>
                </Zoom>
                <Zoom in={!specifyDays} appear={false}>
                    <Box marginTop={-6}>
                        <AdScheduleTimeSelector
                            startTime={startTime}
                            setStartTime={setStartTime}
                            endTime={endTime}
                            setEndTime={setEndTime}
                        />
                    </Box>
                </Zoom>
            </Grid>
        </BlueHeaderContainer>
    );
};

AdScheduling.propTypes = {
    continuous: PropTypes.bool,
    dateRange: PropTypes.array,
    setDateRange: PropTypes.func,
    startTime: PropTypes.string,
    setStartTime: PropTypes.func,
    endTime: PropTypes.string,
    setEndTime: PropTypes.func,
    days: PropTypes.array,
    setDays: PropTypes.func,
    scheduleType: PropTypes.string,
    setScheduleType: PropTypes.func
};
