import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { IconButton, makeStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import EditReportDialog from './EditReportDialog';

const useStyles = makeStyles((theme) => ({
    tableIcon: {
        padding: '4px'
    }
}));

function EditReport({ report, categories, units, refetchReports }) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    return (
        <React.Fragment>
            <IconButton className={classes.tableIcon} onClick={() => setOpen(true)}>
                <Edit />
            </IconButton>
            {open && report ? (
                <EditReportDialog
                    report={report}
                    onClose={() => setOpen(false)}
                    categories={categories}
                    units={units}
                    refetchReports={refetchReports}
                />
            ) : null}
        </React.Fragment>
    );
}

EditReport.propTypes = {
    report: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        isReusable: PropTypes.bool.isRequired,
        isEditable: PropTypes.bool.isRequired,
        sequence: PropTypes.number.isRequired,
        pbiWorkspaceId: PropTypes.string.isRequired,
        pbiReportId: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        units: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            isActive: PropTypes.bool.isRequired
        })
    ).isRequired,
    units: PropTypes.any,
    refetchReports: PropTypes.func.isRequired
};

export default EditReport;
