import gql from 'graphql-tag';

import { FRAGMENT_DATA_SOURCES } from 'graphql/fragments/dataSources';
import { FRAGMENT_PBI_REPORTS } from 'graphql/fragments/pbi.reports';
import { FRAGMENT_UNIT_SIMPLE_REFS } from 'graphql/fragments/units';

export const USER_ID = gql`
    query user {
        user {
            id
        }
    }
`;

export const ME_PERMISSIONS = gql`
    query mePermissions {
        me {
            id
            userPermissions {
                unitId {
                    id
                    name
                }
                permissions {
                    name
                    permission
                }
            }
        }
    }
`;

export const ME = gql`
    query me {
        me {
            id
            firstName
            lastName
            email
            phone
            userPermissions {
                unitId
                permissions {
                    name
                    permission
                }
            }
            activeUnit
            units {
                id
                customerId
                orgNr
                email
                phone
                name
                parentId
                isDeleted
                isDemo
                logo
                externalReports {
                    name
                    url
                }
                location {
                    address1
                    address2
                    postalCode
                    city
                    county
                    country
                }
                connections {
                    facebook {
                        pages {
                            default {
                                id
                                name
                                key
                            }
                        }
                    }
                }
                targeting {
                    id
                    name
                    locations {
                        latitude
                        longitude
                        label
                        radius
                    }
                }
                children {
                    id
                }
                users {
                    id
                    firstName
                    lastName
                    email
                    phone
                    verified
                }
                productFeeds {
                    id
                    fields {
                        name
                        label
                        visibility
                        fieldType
                    }
                }
                productTypes {
                    id
                    fields {
                        id
                        name
                        label
                        visibility
                        fieldType
                    }
                }
                linkExtension {
                    _id
                    unitId
                    extensions {
                        _id
                        name
                        value
                        description
                    }
                }
                reports {
                    ...pbiReport
                }

                dataSources {
                    ...dataSources
                }
            }
        }
    }
    ${FRAGMENT_PBI_REPORTS}
    ${FRAGMENT_DATA_SOURCES}
`;

export const ME_FEEDS__ = gql`
    query me {
        me {
            id
            firstName
            lastName
            email
            phone
            units {
                id
                unitId
                name
                feeds {
                    id
                    filters
                    fields
                }
            }
        }
    }
`;

export const GET_AVAILABLE_LEGITIMATE_USERS = gql`
    query getUsers {
        getUsers {
            id
            verified
            firstName
            lastName
            email
            phone
            units {
                id
                name
            }
        }
    }
`;

export const GET_UNITS = gql`
    query getUnits {
        getUnits {
            ...unit
        }
    }
    ${FRAGMENT_UNIT_SIMPLE_REFS}
`;

export const GET_UNIT_NAME = gql`
    query getUnitName($unitId: ID) {
        getUnit(id: $unitId) {
            name
        }
    }
`;

export const GET_UNIT = gql`
    query getUnit($id: ID) {
        getUnit(id: $id) {
            id
            name
            logo
            orgNr
            email
            phone
            customerId
            isDemo
            externalReports {
                name
                url
            }
            location {
                address1
                address2
                postalCode
                city
                county
                country
            }
            invoice {
                address1
                address2
                postalCode
                city
                county
                country
            }
            users {
                id
                firstName
                lastName
                email
                phone
                verified
                report
                userPermissions {
                    unitId
                    permissions {
                        name
                        permission
                    }
                }
            }
            productFeeds {
                id
                fields {
                    name
                    label
                    visibility
                    fieldType
                }
            }
            productTypes {
                id
                fields {
                    id
                    name
                    label
                    visibility
                    fieldType
                }
            }
            linkExtension {
                _id
                unitId
                extensions {
                    _id
                    name
                    value
                    description
                }
            }
            reports {
                ...pbiReport
            }
            connections {
                facebook {
                    pages {
                        default {
                            id
                            name
                            key
                        }
                        keys {
                            id
                            name
                            key
                        }
                    }
                }
            }
        }
    }
    ${FRAGMENT_PBI_REPORTS}
`;

export const GET_FACEBOOK_BUSINESS_ID = gql`
    query getFacebookBusinessId {
        getFacebookBusinessId
    }
`;

export const GET_FACEBOOK_PAGES = gql`
    query getFacebookPages($input: GetFacebookPagesInput) {
        getFacebookPages(input: $input) {
            id
            key
            name
            units {
                id
                name
                connections {
                    facebook {
                        pages {
                            default {
                                name
                                key
                            }
                            keys {
                                name
                                key
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_MFA_DETAILS_FOR_USER = gql`
    query getMFADetails($userId: ID) {
        getMFADetails(userId: $userId) {
            secret
            otpauthUrl
            userId
            status
        }
    }
`;
